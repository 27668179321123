import { RouteComponentProps } from 'react-router-dom'

import { Theme } from 'components/blocks/ProgressBar/Icons'

export { Theme } from 'components/blocks/ProgressBar/Icons'

export interface ContainerProps extends RouteComponentProps {
  current: string,
  errors?: string[],
  type: Type,
}

export enum Type {
  autoRc1 = 'auto-rc1',
  autoRc2 = 'auto-rc2',
  homeRc1 = 'home-rc1',
}

export interface Props {
  isArrowVisible: {
    left: boolean,
    right: boolean,
  },
  marginLeft: number,
  scrollLeft(): void,
  scrollRight(): void,
  setFirstStepRef(ref: React.ReactNode): void,
  setLastStepRef(ref: React.ReactNode): void,
  setWrapperRef(ref: React.ReactNode): void,
  steps?: Step[],
  styleConsts: {
    mobileStepMargin: number,
    mobileStepWidth: number,
    transitionDurationS: number,
  },
  type: Type,
}

export interface Step {
  icon: Theme,
  status?: string,
  title: string,
}
