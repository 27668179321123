import React, { useState } from 'react'

import LoadingTypeC from './index.presentational'

const LoadingTypeCContainer: React.FC = (): React.ReactElement => {
  const [
    isModalMissingIncidentOpen,
    setModalMissingIncidentOpen,
  ] = useState(
    false,
  )

  return React.createElement(
    LoadingTypeC,
    {
      setModalMissingIncidentOpen,
      modalMissingIncident: {
        isOpen: isModalMissingIncidentOpen,
        onRequestClose: (): void => setModalMissingIncidentOpen(false),
      },
    },
  )
}

export * from './types'

export default LoadingTypeCContainer
