import styled from 'styled-components'

import MEDIA from 'constants/media'


import VehiclesIcon from 'assets/images/icons/progress/Wheel.svg'
import DriversIcon from 'assets/images/icons/progress/Man.svg'
import IncidentsIcon from 'assets/images/icons/progress/Accident.svg'
import PolicyIcon from 'assets/images/icons/progress/Policy.svg'
import CoveragesIcon from 'assets/images/icons/progress/Coverages.svg'

import CarrierIcon from 'assets/images/icons/progress/Carrier.svg'
import HouseholdIcon from 'assets/images/icons/progress/Household.svg'
import ClaimsIcon from 'assets/images/icons/progress/Claims.svg'
import DrivingHistoryIcon from 'assets/images/icons/progress/DrivingHistory.svg'
import CompletPolicyIcon from 'assets/images/icons/progress/CompletePolicy.svg'
import GPSIcon from 'assets/images/icons/quote/GPS.svg'
import HouseIcon from 'assets/images/icons/quote/House.svg'

import { Props } from './types'

/* eslint-disable import/prefer-default-export */
export const Icon = styled.span<Props>`
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  ${MEDIA.medium`
    height: 60px;
    width: 60px;
  `}

  ${(props: Props): string | undefined => (props.theme === 'vehicles' && `
    background-image: url(${VehiclesIcon});
  `) || (props.theme === 'drivers' && `
    background-image: url(${DriversIcon});
  `) || (props.theme === 'incidents' && `
    background-image: url(${IncidentsIcon});
  `) || (props.theme === 'policy' && `
    background-image: url(${PolicyIcon});
  `) || (props.theme === 'coverages' && `
    background-image: url(${CoveragesIcon});
  `) || (props.theme === 'carrier' && `
    background-image: url(${CarrierIcon});
  `) || (props.theme === 'household' && `
    background-image: url(${HouseholdIcon});
  `) || (props.theme === 'claims' && `
    background-image: url(${ClaimsIcon});
  `) || (props.theme === 'completepolicy' && `
    background-image: url(${CompletPolicyIcon});
  `) || (props.theme === 'drivinghistory' && `
    background-image: url(${DrivingHistoryIcon});
  `) || (props.theme === 'address' && `
    background-image: url(${GPSIcon});
  `) || (props.theme === 'home' && `
    background-image: url(${HouseIcon});
  `) || undefined}
`
