import React from 'react'

import Img from 'components/base/Img'

import Spinner from 'assets/images/gold-loader.svg'

import Wrapper from './styles'

const LoadingRateCall: React.FC = (): React.ReactElement => (
  <Wrapper>
    <Img src={Spinner} src2x={undefined} alt='Rate Loader' />
  </Wrapper>
)

export default LoadingRateCall
