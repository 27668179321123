import React from 'react'

import Text from 'components/base/Text'
import { ErrorIcon } from 'components/base/Icon/styles'
import Loading from 'components/base/Loading'

import Button from 'components/base/Button'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'

import { Props } from './types'

import {
  ResultWrap,
  ResultContainer,
  CostItem,
  QuoteCost,
  ButtonWrap,
  LogoWrap,
  QuoteStatus,
  ResultMessage,
} from './styles'

const CarrierQuoteResult: React.FC<Props> = ({
  buttons,
  formatPrice,
  isSameCarrierError,
  logo,
  monthly,
  monthlyDownPayment,
  semiyearly,
  setIsModalKickoutOpen,
  status,
  timeoutLevel,
  yearly,
}): React.ReactElement => (
  <ResultWrap>
    <ResultContainer>
      <LogoWrap>{logo}</LogoWrap>
      <QuoteCost>
        {((status === 'failed' || (timeoutLevel && timeoutLevel.isHard)) && (
          <QuoteStatus>
            <Text theme='medium' error={true}>Oops!</Text> <ErrorIcon />
          </QuoteStatus>
        )) || ((status === 'pending' || status === 'timeout') && (
          <QuoteStatus>
            <Text theme='medium'>Prices Coming</Text> <Loading theme='rates' />
          </QuoteStatus>
        )) || (status === 'success' && (
        <>
          {(yearly && (
            <CostItem>
              ${formatPrice(yearly)}
              <span>/YR</span>
            </CostItem>
          )) || semiyearly && (
            <CostItem>
              ${formatPrice(semiyearly)}
              <span>/6 MTHS</span>
            </CostItem>
          )}
          {monthly && (
            <CostItem>
              ${formatPrice(monthly)}
              <span>/MO</span>
            </CostItem>
          )}
          {monthlyDownPayment && (
            <CostItem>
              ${formatPrice(monthlyDownPayment)}
              <span> DOWN</span>
            </CostItem>
          )}
          {!monthly && <CostItem size='small'>Other payment options available</CostItem>}
        </>
        )) || undefined}
      </QuoteCost>
    </ResultContainer>
    {(status === 'failed' && (
      <ResultMessage>
        <Paragraph theme={ParagraphTheme.medium}>
          An Insurance Concierge<sup>®</sup> can help provide you rates for this carrier.
          {(isSameCarrierError && ' We cannot return a price from your most recent insurance carrier.') || undefined}
          {(buttons.length > 1 && ' To get pricing from this carrier please select one of the options below.') || undefined}
        </Paragraph>
      </ResultMessage>
    )) || undefined}
    {(status === 'timeout' && timeoutLevel && (
      <ResultMessage>
        <Paragraph theme={ParagraphTheme.medium}>
          {timeoutLevel.message}
        </Paragraph>
      </ResultMessage>
    )) || undefined}
    {(buttons.length || (status === 'timeout' && timeoutLevel && timeoutLevel.showConciergeButton)) ? (
      <ButtonWrap>
        {buttons}
        {(status === 'timeout' && timeoutLevel && timeoutLevel.showConciergeButton && (
          <Button
            key='kickout'
            secondary={true}
            onClick={(): void => {
              setIsModalKickoutOpen && setIsModalKickoutOpen(true)
            }}
          >
            Send to an Insurance Concierge
          </Button>
        )) || undefined}
      </ButtonWrap>
    ) : undefined}
  </ResultWrap>
)

export default CarrierQuoteResult
