import React from 'react'

import ProgressIcon from 'components/blocks/ProgressBar/Icons'

import { Type } from '../types'

import {
  IconWrap,
  LinkStep,
  StatusIcon,
  Step,
  Title,
} from './styles'

import {
  Props,
  Theme,
} from './types'

const ProgressBarStep: React.FC<Props> = ({
  icon,
  forwardRef,
  status,
  styleConsts,
  title,
  type,
}): React.ReactElement => {
  let linkTo: string | false | undefined = undefined
  switch (type) {
    case Type.autoRc1:
      linkTo = status && (
        (icon === Theme.vehicles && '/quote/new/auto/vehicles') ||
        (icon === Theme.drivers && '/quote/new/auto/drivers') ||
        (icon === Theme.incidents && '/quote/new/auto/incidents') ||
        (icon === Theme.policy && '/quote/new/auto/policy') ||
        (icon === Theme.coverages && '/quote/new/auto/coverage-levels')
      )
      break
    case Type.autoRc2:
      linkTo = status && (
        (icon === Theme.carrier && '/quote/new/auto/additional-info') ||
        (icon === Theme.household && '/quote/new/loading/type-a') ||
        (icon === Theme.claims && '/quote/new/loading/type-b') ||
        (icon === Theme.drivinghistory && '/quote/new/loading/type-c') ||
        (icon === Theme.completepolicy && '/quote/new/pricing')
      )
      break
    case Type.homeRc1:
      linkTo = status && (
        (icon === Theme.address && '/quote/new/home/address') ||
        (icon === Theme.home && '/quote/new/home/about') ||
        (icon === Theme.policy && '/quote/new/home/policy') ||
        (icon === Theme.coverages && '/quote/new/home/coverage-levels')
      )
      break
  }

  const children = (
    <div ref={forwardRef}>
      <IconWrap>
        <ProgressIcon theme={icon} />
        <StatusIcon />
      </IconWrap>
      <Title>{title}</Title>
    </div>
  )

  return (
    (linkTo && (
      <LinkStep className={status} style-consts={styleConsts} to={linkTo}>
        {children}
      </LinkStep>
    )) || (
      <Step className={status} style-consts={styleConsts}>
        {children}
      </Step>
    )
  )
}

export default ProgressBarStep
