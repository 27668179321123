import React from 'react'
import classnames from 'classnames'
import {
  Link,
  withRouter,
} from 'react-router-dom'

import MainNav from 'components/MainNav'
import SiteLogo from 'components/base/SiteLogo'
import Text from 'components/base/Text'

import {
  HeaderWrap, HeaderContent, HeaderCTA,
} from './styles'

import { Props } from './types'

const Header: React.FC<Props> = ({
  isAtTop,
  isVisible,
  location: { pathname },
}): React.ReactElement | null =>
  (pathname !== '/client-self-service' && (
    <HeaderWrap
      className={classnames({
        'header--hidden': !isVisible && !isAtTop,
        'header--scrolling': !isAtTop,
      })}
    >
      <HeaderContent>
        <MainNav />
        <Link to='/'>
          <SiteLogo />
        </Link>
        <HeaderCTA className='headerCTA'>
          <Text size='small' weight='bold' color='BLUE'>Call us at</Text>
          <Text size='xlarge' weight='bold' color='BLUE'>1-800-211-8660</Text>
        </HeaderCTA>
      </HeaderContent>
    </HeaderWrap>
  )) ||
  null // eslint-disable-line no-null/no-null

export default withRouter(Header)
