
import styled from 'styled-components'

import COLORS from 'constants/colors'
import MEDIA from 'constants/media'
import FONTS from 'constants/fonts'


import { StyleProps } from './types'

export const ResultWrap = styled.div`
  background: ${COLORS.WHITE};
  border: 2px solid ${COLORS.BORDERS};
  max-width: 640px;
  margin: 30px auto;
  padding: 5px 20px 20px 20px;
`

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MEDIA.medium`
    flex-direction: row;
    justify-content: space-between;
  `}
`

export const CostItem = styled.div<StyleProps>`
  font-size: ${FONTS.PARAGRAPHMOBILE};
  display: inline-block;
  margin-right: 10px;
  span {
    color: ${COLORS.GREYDARK};
    font-size: 18px;
  }
  ${MEDIA.medium`
    font-size: ${(props: StyleProps): string => props.size === 'small' ? `${FONTS.PARAGRAPHSMALL}` : `${FONTS.PARAGRAPH}`};
    display: block;
    span {
      font-size: 20px;
    }
  `}
`

export const QuoteCost = styled.div`
  margin: 0;
  text-align: center;
  ${MEDIA.medium`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
  `}
`

export const ResultMessage = styled.div`
  margin:0 auto 20px;
  text-align: center;
  max-width: 450px;
`

export const QuoteStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }

`

export const LogoWrap = styled.div`
  border: 1px solid ${COLORS.BORDERS};
  margin: 10px 0;
  ${MEDIA.medium`
    border: none;
    margin: 0;
    > div {
      background-position: left center;
    }
  `}
`

export const ButtonWrap = styled.div`
  border-top: 1px solid ${COLORS.BORDERS};
  margin-top: 10px;
  padding-top: 15px;
  text-align: center;
  > div {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${MEDIA.medium`
    margin-top: 0;
`}
`
