
import styled from 'styled-components'

import MEDIA from 'constants/media'

import COLORS from 'constants/colors'

import Link from '../base/Link'


export const FooterWrap = styled.div`
  position: relative;
  font-size: 14px;
  background: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  text-align: center;
  padding: 25px 20px;
  min-height: 80px;
  ${MEDIA.medium`
    font-size: 15px;
  `}
`

export const FooterLink = styled(Link)`
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.WHITE};
  }
`

export const FooterTop = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const FooterNav = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px auto 10px;
  border-bottom: thin solid white;
  max-width: 800px;
  padding: 0 0 13px 0;
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
  a {
    padding: 5px;
  }
  ${MEDIA.small`
    justify-content: space-between;
    a {
    &:first-child {padding-left:0;}
    &:last-child {padding-right:0;}
  }
  `}
`

export const FooterNavSub = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6px auto 10px;
`

export const FooterNavLinkSub = styled(Link)`
  margin: 0 10px 5px;
  font-size: 13px;
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
`

export const FooterNavLinkSubSpan = styled.span`
  margin: -2px 0 0 0;
`

export const FooterNavLink = styled(Link)`
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
`

export const FooterNavLinkGrey = styled(Link)`
  text-decoration: underline;
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.GREYMEDIUM};
    text-decoration: underline;
  }
`

export const FooterNavLinkExternal = styled(FooterNavLinkGrey)`
  background-image: none;
  padding-right: 0;
`

export const Version = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 10px;
  color: ${COLORS.BLUE};
`

export const Copyright = styled.div`
  font-size: 10px;
  color: ${COLORS.GREYMEDIUM};
  :link,
  :hover,
  :active,
  :visited {
    color: ${COLORS.GREYMEDIUM};
    text-decoration: none;
  }
`

export const FooterLegal = styled(Copyright)`
  max-width: 640px;
  margin: 0 auto 15px;
`
