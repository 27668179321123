
import dottie from 'dottie'

import { get } from 'utils/apis/insuranceLounge'
import type { ApiResponse } from 'utils/apis/insuranceLounge'

import type { QuotesRequest } from 'state/quotes/types'

import { initialState } from 'state/quotes/reducers'

import packageJson from '../../../package.json'

export default async (insuranceType: string, level: number, stateAbbr: string): Promise<QuotesRequest> => {
  let response: ApiResponse | undefined
  try {
    response = await get(`/dev/quote-requests/${insuranceType}/${level}/${stateAbbr}/low-risk?clientPlatform=web&clientVersion=${packageJson.version}&firstName=WebPrefill`)
  }
  catch (e) {
    throw new Error('Unable to fetch prefill data from the server.')
  }

  if (!response) {
    throw undefined
  }

  if (!response.success) {
    throw response.firstErrorMessage || undefined
  }

  const quotesRequest: QuotesRequest = Object.entries(response.data).reduce((acc: QuotesRequest, [
    key,
    value,
  ]) => {
    if (value) {
      acc[key as keyof QuotesRequest] = value
    }

    return acc
  }, {})

  dottie.set(quotesRequest, `${insuranceType}.isPrefill`, true)

  if (insuranceType === 'home') {
    dottie.set(quotesRequest, 'home.completeAddress-isSuggestionSelected', true)
  }

  return {
    ...initialState.current.request,
    ...quotesRequest,
  }
}
