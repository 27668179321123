export enum Theme {
  carrier = 'carrier',
  claims = 'claims',
  completepolicy = 'completepolicy',
  coverages = 'coverages',
  drivers = 'drivers',
  drivinghistory = 'drivinghistory',
  household = 'household',
  incidents = 'incidents',
  policy = 'policy',
  vehicles = 'vehicles',
  home = 'home',
  address = 'address',
}

export interface Props {
  theme: Theme,
}
