import React, {
  useContext,
  useEffect,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { setQuotingWrapperBgImageIndex } from 'state/app/actions'
import { getQuotingWrapperBgImageIndex } from 'state/app/selectors'

import { MainNavContext } from 'components/MainNav/context'

import QuotingHeading from 'components/blocks/QuotingHeading'

import { Props } from './types'

import {
  QuotingWrap,
  QuotingContent,
  FormWrapper,
} from './styles'

const numAvailaleBgImages = 3

const QuotingWrapper: React.FC<Props> = ({
  children,
  theme,
  title,
  ...props
}): React.ReactElement | null => {
  const dispatch = useDispatch()

  const bgImageIndex = useSelector(getQuotingWrapperBgImageIndex)

  useEffect(() => {
    let newBgImageIndex = bgImageIndex

    while (newBgImageIndex === bgImageIndex) {
      newBgImageIndex = Math.round((Math.random() * (Math.abs(numAvailaleBgImages - 1))) + 1)
    }

    dispatch(setQuotingWrapperBgImageIndex(newBgImageIndex))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { isOpen: isMainNavOpen } = useContext(MainNavContext)

  if (isMainNavOpen) {
    return null /* eslint-disable-line no-null/no-null */ // https://bitbucket.org/flowerpress/insurancelounge.com/issues/487/menu-items-not-visible-on-wide-screen
  }

  return (
    <QuotingWrap bgImageIndex={bgImageIndex}>
      <QuotingContent {...props}>
        <FormWrapper>
          <QuotingHeading theme={theme} title={title} />

          {children}
        </FormWrapper>
      </QuotingContent>
    </QuotingWrap>
  )
}

export * from './types'

export default QuotingWrapper
