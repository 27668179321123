import styled from 'styled-components'

import FONTS from 'constants/fonts'
import COLORS from 'constants/colors'
import MEDIA from 'constants/media'

export const SectionDividerContent = styled.div`
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
  ${MEDIA.medium`
    display: flex;
    align-items: flex-end;
    width: 100%;
  `}
`

export const SectionTitle = styled.h2`
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};
  font-size: ${FONTS.FONTSECTIONDIVIDERMOBILE};
  border-bottom: 2px solid ${COLORS.BLACK};
  margin: 0;
  padding-bottom: 10px;
  ${MEDIA.medium`
    font-size: ${FONTS.FONTSECTIONDIVIDER};
    text-align: left;
    flex: 1;
    margin-left: 10px;
`}
`
