import styled from 'styled-components'

import CheckBox from 'assets/images/checkbox-checked.svg'
import CheckBoxUnchecked from 'assets/images/checkbox-unchecked.svg'

import { Props } from './types'

export * from './types'

export default styled.span<Props>`
  display: inline-block;
  width: 35px;
  height: 37px;
  vertical-align: bottom;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${(props: Props): string => (props.checked ? '35px 37px' : '29px 29px')};
  background-image: url(${(props: Props): string => props.checked ? `${CheckBox}` : `${CheckBoxUnchecked}`});
`
