import React, {
  useEffect,
  useState,
} from 'react'

import { CarrierQuoteStatus } from 'state/quotes/types'

import {
  ContainerProps,
  TimeoutLevel,
} from './types'

import CarrierQuoteResult from './index.presentational'

const timeoutLevels: TimeoutLevel[] = [
  {
    delay: 25000,
    isHard: false,
    message: 'This carrier is working on your request... ',
    showConciergeButton: false,
  },
  {
    delay: 10000,
    isHard: false,
    message: 'We are awaiting results... ',
    showConciergeButton: false,
  },
  {
    delay: 20000,
    isHard: false,
    message: 'Prices should appear soon...',
    showConciergeButton: false,
  },
  {
    delay: 25000,
    isHard: false,
    message: 'We are still compiling prices. Tired of waiting?',
    showConciergeButton: true,
  },
  {
    delay: 40000,
    isHard: true,
    message: 'Sorry! This carrier does not have pricing available at this time.',
    showConciergeButton: true,
  },
]

const CarrierQuoteResultContainer: React.FC<ContainerProps> = ({
  buttons,
  status,
  ...props
}): React.ReactElement => {
  const [
    timeoutLevelIndex,
    setTimeoutLevelIndex,
  ] = useState(-1)

  useEffect(() => {
    if (status === 'success') {
      setTimeoutLevelIndex(-1)

      return
    }

    const nextTimeoutLevel = timeoutLevels[timeoutLevelIndex + 1]
    if (!nextTimeoutLevel) {
      return
    }

    let timer: number | undefined = undefined

    if (timer) {
      clearTimeout(timer)
    }

    const { delay } = nextTimeoutLevel
    timer = setTimeout((): void => {
      setTimeoutLevelIndex(timeoutLevelIndex + 1)
    }, delay)

    return (): void => {
      clearTimeout(timer)
    }
  }, [
    status,
    timeoutLevelIndex,
  ])

  if (!(buttons && Array.isArray(buttons))) {
    buttons = (buttons && [ buttons ]) || []
  }
  buttons = buttons.filter(button => button)

  const timeoutLevel = timeoutLevels[timeoutLevelIndex]

  if (timeoutLevel && status === CarrierQuoteStatus.pending) {
    status = CarrierQuoteStatus.timeout
  }

  return React.createElement(
    CarrierQuoteResult,
    {
      ...props,
      buttons,
      formatPrice: (cents: number): string => (cents / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      status,
      timeoutLevel,
    },
  )
}

export default CarrierQuoteResultContainer
