import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { createBroswerContext } from 'use-sse'

import {
  persistor,
  store,
} from '../../../state/configureStore'

const BroswerDataContext = createBroswerContext()

export const AppBrowserWrapper = ({ children }: { children: React.ReactNode }) => (
  <Provider store={store}>
    <PersistGate loading={undefined} persistor={persistor}>
      <BrowserRouter>
        <BroswerDataContext>
          {children}
        </BroswerDataContext>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
