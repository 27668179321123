import React, { useState } from 'react'

import LoadingTypeA from './index.presentational'

const LoadingTypeAContainer: React.FC = (): React.ReactElement => {
  const [
    isModalMissingDriverOpen,
    setModalMissingDriverOpen,
  ] = useState(
    false,
  )

  return React.createElement(
    LoadingTypeA,
    {
      setModalMissingDriverOpen,
      modalMissingDriver: {
        isOpen: isModalMissingDriverOpen,
        onRequestClose: (): void => setModalMissingDriverOpen(false),
      },
    },
  )
}

export * from './types'

export default LoadingTypeAContainer
