
import styled from 'styled-components'

import FONTS from 'constants/fonts'
import COLORS from 'constants/colors'
import MEDIA from 'constants/media'


import { Props } from './types'

const getFontSize = (size?: 'small' | 'medium' | 'large' | 'xlarge'): string => {
  switch (size) {
    case 'small':
      return FONTS.TEXTSMALL
    case 'medium':
      return FONTS.TEXTMEDIUM
    case 'xlarge':
      return FONTS.TEXTXLARGE
    case 'large':
    default:
      return FONTS.TEXTLARGE
  }
}

const getFontSizeMobile = (size?: 'small' | 'medium' | 'large' | 'xlarge'): string => {
  switch (size) {
    case 'small':
      return FONTS.TEXTSMALL
    case 'large':
      return FONTS.TEXTMEDIUM
    case 'xlarge':
      return FONTS.TEXTLARGE
    case 'medium':
    default:
      return FONTS.TEXTMEDIUM
  }
}

/* eslint-disable import/prefer-default-export */
export const StyledText = styled.span<Props>`
  color: ${(props: Props): string | undefined => COLORS[props.color as keyof typeof COLORS]};
  font-size: ${(props: Props): string => getFontSizeMobile(props.size)};
  font-weight: ${(props: Props): string => props.weight === 'bold' ? FONTS.WEIGHTBOLD : FONTS.WEIGHTLIGHT};
  font-family: ${FONTS.FAMILYPRIMARY};
  ${MEDIA.medium`
    font-size: ${(props: Props): string => getFontSize(props.size)};
    `}
  ${(props: Props): string => (props.theme === 'medium' && `
    font-size: ${FONTS.PARAGRAPHSMALL};
    ${MEDIA.medium`
      font-size: ${FONTS.PARAGRAPHMEDIUM};
    `}
  `) || ''}

  ${(props: Props): string => (props.error && `
    color: ${COLORS.ERROR};
  `) || ''}
`
