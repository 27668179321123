import React from 'react'

import { Props } from './types'

import {
  SectionDividerContent,
  SectionTitle,
} from './styles'

const SectionDivider: React.FC<Props> = ({
  icon,
  title,
}): React.ReactElement => (
  <SectionDividerContent>
    {icon}
    <SectionTitle>{title}</SectionTitle>
  </SectionDividerContent>
)

export * from './types'

export default SectionDivider
