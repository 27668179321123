

import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

import CheckMarkImg from 'assets/images/icons/progress/CheckMark.svg'

import ExclamationMarkImg from 'assets/images/icons/progress/ExclamationMark.svg'

export const IconWrap = styled.span``

export const StatusIcon = styled.span``

const stepStyles = css`
  display: block;
  align-items: center;
  text-align: center;
  margin: ${(props): string => props['style-consts'].mobileStepMargin}px;

  ${IconWrap} {
    background: #fff;
    border: 2px solid #d0ebeb;
    border-radius: 50%;
    margin: 5px auto;
    width: ${(props): string => props['style-consts'].mobileStepWidth}px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${MEDIA.medium`
      margin: 10px auto;
      width: 115px;
      height: 115px;
    `}
  }

  ${StatusIcon} {
    position: absolute;
    left: calc(50% - 13px);
    bottom: -13px;
    background: #fff no-repeat center center;
    background-size: 15px auto;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    padding: 10.5px;
    height: 15px;
    width: 15px;
    display: none;
    ${MEDIA.medium`
      left: calc(50% - 16px);
      bottom: -10px;
      padding: 14.5px;
      height: 20px;
      width: 20px;
    `}
  }

  &.active {
    ${IconWrap} {
      border-color: #143d8d;
      box-shadow: 0px 0px 10px 0px #00aeef;
    }
  }
  &.finished {
    ${IconWrap} {
      border-color: #009900;
    }
    ${StatusIcon} {
      display: block;
      background-image: url(${CheckMarkImg});
      background-size: 16px auto;
      border-color: #d0ebeb;
      ${MEDIA.medium`
        background-size: 20px auto;
      `}
    }
  }
  &.errored {
    ${IconWrap} {
      border-color: #990000;
    }
    ${StatusIcon} {
      display: block;
      background-image: url(${ExclamationMarkImg});
      background-size: 3px 16px;
      border-color: #e6c6c6;
    }
  }

  ${MEDIA.medium`
    display: block;
    width: 100%;
    max-width: 480px;
  `}
`

export const Step = styled.span`
  ${stepStyles}
`

export const LinkStep = styled(Link)`
  ${stepStyles}

  &:active,
  &:focus,
  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
  }
`

export const Title = styled.span`
  font-family: ${FONTS.FAMILYSECONDARY};
  font-size: ${FONTS.PARAGRAPH};
  font-weight: ${FONTS.WEIGHTDEMI};
  color: ${COLORS.BLACK};
  margin: 0;
  position: relative;
  text-align: center;
  display: none;
  ${MEDIA.medium`
    text-align: center;
    padding: 0 20px;
    margin-left: 0;
    display: block;
  `}
`
