
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  shallowEqual,
  useSelector,
} from 'react-redux'
import { withRouter } from 'react-router-dom'

import { sizes } from 'constants/media'

import { ScreenWithErrors } from 'state/quotes/types'

import { RootState } from 'state/types'

import useWindowSize from 'hooks/useWindowSize'

import {
  getQuoteScreensWithErrors,
  getQuoteResponseValue,
} from 'state/quotes/selectors'

import { HeaderContext } from 'components/Header/context'
import { ScrollToTopContext } from 'components/App/ScrollToTop/context'

import {
  ContainerProps,
  Props,
  Step,
  Theme,
} from './types'

import ProgressBar from './index.presentational'

const setsOfSteps = {
  'auto-rc1': [
    {
      icon: Theme.vehicles,
      name: 'vehicles',
      title: 'Vehicles',
    },
    {
      icon: Theme.drivers,
      name: 'drivers',
      title: 'Drivers',
    },
    {
      icon: Theme.incidents,
      name: 'incidents',
      title: 'Incidents',
    },
    {
      icon: Theme.policy,
      name: 'policy',
      title: 'Policy',
    },
    {
      icon: Theme.coverages,
      name: 'coverages',
      title: 'Coverages',
    },
  ],
  'auto-rc2': [
    {
      icon: Theme.carrier,
      name: 'carrier',
      title: 'Carrier Questions',
    },
    {
      icon: Theme.household,
      name: 'household',
      title: 'Household',
    },
    {
      icon: Theme.claims,
      name: 'claims',
      title: 'Claims',
    },
    {
      icon: Theme.drivinghistory,
      name: 'drivinghistory',
      title: 'Driving History',
    },
    {
      icon: Theme.completepolicy,
      name: 'completepolicy',
      title: 'Complete Policy',
    },
  ],
  'home-rc1': [
    {
      icon: Theme.address,
      name: 'address',
      title: 'Address',
    },
    {
      icon: Theme.home,
      name: 'about',
      title: 'Home',
    },
    {
      icon: Theme.policy,
      name: 'policy',
      title: 'Policy',
    },
    {
      icon: Theme.coverages,
      name: 'coverages',
      title: 'Coverages',
    },
  ],
}

const styleConsts = {
  mobileStepMargin: 8,
  mobileStepWidth: 75,
  transitionDurationS: 0.25,
}

const ProgressBarContainer: React.FC<ContainerProps> = ({
  children,
  current,
  errors = [],
  location,
  type,
}): React.ReactElement => {
  const windowSize = useWindowSize()

  const [
    isArrowVisible,
    setIsArrowVisible,
  ] = useState({
    left: false,
    right: true,
  })
  const [
    wrapperRef,
    setWrapperRef,
  ] = useState<HTMLInputElement|undefined>(undefined)
  const [
    lastStepRef,
    setLastStepRef,
  ] = useState<HTMLInputElement|undefined>(undefined)
  const [
    firstStepRef,
    setFirstStepRef,
  ] = useState<HTMLInputElement|undefined>(undefined)
  const [
    marginLeft,
    setMarginLeft,
  ] = useState(0)
  const [
    isScrollLocked,
    setIsScrollLocked,
  ] = useState(false)
  const [
    isWindowSizeMobile,
    setIsWindowSizeMobile,
  ] = useState(false)

  const [
    screensWithErrors,
    responseErrors,
  ] = useSelector((state: RootState) => [
    getQuoteScreensWithErrors(state, 'rateCallOne'),
    getQuoteResponseValue(state, 'rateCallOne.error.errors'),
  ], shallowEqual)

  const updateArrows = useCallback(() => {
    if (!firstStepRef || !lastStepRef || !wrapperRef) {
      return
    }

    const wrapperRightEdge = wrapperRef.getBoundingClientRect().x + wrapperRef.getBoundingClientRect().width
    const stepsLeftEdge = firstStepRef.getBoundingClientRect().x
    const stepsRightEdge = lastStepRef.getBoundingClientRect().x + lastStepRef.getBoundingClientRect().width

    setIsArrowVisible({
      left: stepsLeftEdge < 0,
      right: stepsRightEdge > wrapperRightEdge,
    })
  }, [
    firstStepRef,
    lastStepRef,
    wrapperRef,
  ])

  useEffect(() => {
    updateArrows()

    setIsWindowSizeMobile(!windowSize.width || windowSize.width < sizes.xsmall)
  }, [
    updateArrows,
    windowSize,
  ])

  const { setIsEnabled: setIsScrollToTopEnabled } = useContext(ScrollToTopContext)

  const { setIsShowOnScrollEnabled: setIsShowHeaderOnScrollEnabled } = useContext(HeaderContext)

  useEffect(() => {
    if (!wrapperRef) {
      return (): void => undefined
    }

    let timeout1: number, timeout2: number
    if (isWindowSizeMobile) {
      setIsScrollToTopEnabled(false)

      timeout1 = setTimeout(() => {
        const y = wrapperRef.getBoundingClientRect().top

        setIsShowHeaderOnScrollEnabled(false)
        window.scrollBy(0, y)
        timeout2 = setTimeout(() => setIsShowHeaderOnScrollEnabled(true), 100)
      }, 10)
    }
    else{
      setIsScrollToTopEnabled(true)
    }

    return (): void => {
      timeout1 && clearTimeout(timeout1)
      timeout2 && clearTimeout(timeout2)
    }
  }, [
    setIsScrollToTopEnabled,
    setIsShowHeaderOnScrollEnabled,
    isWindowSizeMobile,
    location,
    wrapperRef,
  ])

  // This is left here so that static can manually pass children for progress bars that are not yet integrated dynamically
  if (children) {
    return React.createElement(ProgressBar, {} as Props, children)
  }

  const scroll = (multiplier = 1): void => {
    if (isScrollLocked) {
      return
    }

    setIsScrollLocked(true)

    const stepSize = styleConsts.mobileStepWidth + (styleConsts.mobileStepMargin * 2)
    const newMarginLeft = marginLeft + (stepSize * multiplier)

    setMarginLeft(newMarginLeft)

    setTimeout(() => {
      updateArrows()

      setIsScrollLocked(false)
    }, styleConsts.transitionDurationS * 1000)
  }

  const steps: Step[] = setsOfSteps[type].map(step => ({
    ...step,
    status:
      (screensWithErrors.find((screen: ScreenWithErrors) => screen.path === `/quote/new/${type.replace(/^([^-]*)-.*$/, '$1')}/${step.name}`) && 'errored') ||
      (errors.includes(step.name) && 'errored') ||
      (current === step.name && 'active') ||
      (responseErrors && 'finished') ||
      (current === 'all-finished' && 'finished') ||
      (setsOfSteps[type].findIndex(currentStep => currentStep.name === current) >
        setsOfSteps[type].findIndex(thisStep => thisStep.name === step.name) &&
        'finished') ||
      undefined,
  }))

  return React.createElement(ProgressBar, {
    isArrowVisible,
    marginLeft,
    scrollLeft: () => scroll(1),
    scrollRight: () => scroll(-1),
    setFirstStepRef,
    setLastStepRef,
    setWrapperRef,
    steps,
    styleConsts,
    type,
  })
}

export * from './types'

export default withRouter(ProgressBarContainer)
