import React from 'react'

import Content from 'components/base/Content'
import Title from 'components/base/Title'
import ButtonRow from 'components/base/Button/Row'
import Button from 'components/base/Button'
import SectionDivider from 'components/base/SectionDivider'
import { QuoteIconFolder } from 'components/base/Icon/styles'
import QuotingWrapper, { Theme as QuotingWrapperTheme } from 'components/blocks/QuotingWrapper'

import LoadingRateCall from 'components/base/Loading/RateCall'
import List, { Theme as ListTheme } from 'components/base/List'
import RateCallCheckBox from 'components/blocks/RateCallCheckBox'

import MissingIncidentTypeB from 'components/blocks/Modal/MissingIncidentTypeB'
import ProgressBar, { Type as ProgressBarType } from 'components/blocks/ProgressBar'

import { Props } from './types'

const LoadingTypeC: React.FC<Props> = ({
  modalMissingIncident,
  setModalMissingIncidentOpen,
}): React.ReactElement => (
  <QuotingWrapper noPadding={true} theme={QuotingWrapperTheme.auto}>
    <MissingIncidentTypeB {...modalMissingIncident} />

    <ProgressBar type={ProgressBarType.autoRc2} current='drivinghistory' />

    <Content margined={true} center={true}>
      <Title level={3}>
        Thanks, Annika! Please hold while we check for complete incident
        history and motor vehicle reports.
      </Title>
      <LoadingRateCall />
      <SectionDivider icon={<QuoteIconFolder />} title='Incident Summary' />
      <List theme={ListTheme.incidentList}>
        <li>
          <RateCallCheckBox checked={true} /> Driving History
        </li>
        <li>
          <RateCallCheckBox checked={true} /> Tickets
        </li>
        <li>
          <RateCallCheckBox checked={true} /> Other Accidents
        </li>
      </List>
    </Content>
    <Content margined={true} center={true}>
      <Title level={3}>Review your results to continue.</Title>
      <ButtonRow>
        <Button
          onClick={(): void =>
            setModalMissingIncidentOpen(!modalMissingIncident.isOpen)
          }
          center={true}
          wide={true}
        >
          Review Results
        </Button>
      </ButtonRow>
    </Content>
  </QuotingWrapper>
)

export default LoadingTypeC
