import React from 'react'

import { Theme } from 'components/base/Button'
import Title from 'components/base/Title'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'

import Modal from 'components/blocks/Modal/index'

import { Props } from './types'


const ModalMissingIncident: React.FC<Props> = (props): React.ReactElement => (
  <div>
    <Modal
      {...props}
      needHelp={true}
      label='Missing Incident Modal'
      buttons={[
        {
          label: 'Review Incident',
          center: true,
          theme: Theme.link,
          to: '/quote/new/auto/missing-incident/type-a',
        },
      ]}
    >
      <Title level={3}>Oops!</Title>
      <Paragraph theme={ParagraphTheme.intro}>
        We found an incident missing from your entries.
      </Paragraph>
    </Modal>
  </div>
)

export default ModalMissingIncident
