import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'matchmedia-polyfill'
import smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import { loadableReady } from '@loadable/component'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import { AppBrowserWrapper } from './components/App/AppBrowserWrapper'

import initSentry from './utils/sentry/init'

import './index.css'

import Root from './root'

smoothscroll.polyfill()

initSentry(Sentry)


const ClientApp: React.FC = (): React.ReactElement => (
  <AppBrowserWrapper>
    <Root />
  </AppBrowserWrapper>
)

loadableReady(() => ReactDOM.hydrate(<ClientApp />, document.getElementById('root')))

if (module.hot) {
  module.hot.accept()
}
