import React from 'react'

import Content, { Theme as ContentTheme } from 'components/base/Content'
import Title from 'components/base/Title'
import ButtonRow from 'components/base/Button/Row'
import Link, { Theme as LinkTheme } from 'components/base/Link'
import CarrierQuoteResult from 'components/blocks/CarrierQuoteResult'
import CarrierLogo, { Theme as CarrierLogoTheme } from 'components/blocks/CarrierLogo'
import QuotingWrapper, { Theme as QuotingWrapperTheme } from 'components/blocks/QuotingWrapper'
import ProgressBar, { Type as ProgressBarType } from 'components/blocks/ProgressBar'

const RateCallOnePointFive: React.FC = (): React.ReactElement => (
  <QuotingWrapper noPadding={true} theme={QuotingWrapperTheme.auto}>
    <ProgressBar type={ProgressBarType.autoRc2} current='household' />

    <Title level={3}>Looking good so far!</Title>
    <Content theme={ContentTheme.paper} center={true}>
      <Title level={3}>
        Here is your personalized Safeco Insurance price. If all looks good,
        we’ll run some final reports, then issue the policy.
      </Title>
      <Content margined={true}>
        <CarrierQuoteResult
          logo={<CarrierLogo theme={CarrierLogoTheme.safeco} />}
          monthly={9800}
          yearly={118300}
          buttons={undefined}
        />
      </Content>
    </Content>
    <Content margined={true} center={true}>
      <Title level={3}>Do these rates look good?</Title>
      <ButtonRow>
        <Link center={true} wide={true} theme={LinkTheme.button} to='/quote/new/loading/type-b'>
          Yes, Please Continue
        </Link>
      </ButtonRow>
    </Content>
  </QuotingWrapper>
)

export default RateCallOnePointFive
