import React from 'react'

import { Props } from './types'

import { StyledText } from './styles'

const Text: React.FC<Props> = ({
  theme,
  ...props
}): React.ReactElement => <StyledText theme={theme} {...props} />

export * from './types'

export default Text
