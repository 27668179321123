
import styled from 'styled-components'

import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


import ArrowLeftImg from 'assets/images/icons/arrow-back.svg'
import ArrowRightImg from 'assets/images/icons/arrow-forward.svg'

export const ProgressBarWrap = styled.div`
  background: #eff8f7;
  background: linear-gradient(to bottom, #eff8f7 0%, #ffffff 100%);
  padding: 15px 25px 0;
  text-align: center;
  margin-top: 10px;
  position: relative;
  ${MEDIA.medium`
    padding: ${LAYOUT.PADDING};
  `}
`

export const ProgressBarContentWrap = styled.div`
  width: 100%;
  overflow: hidden;
`

export const ProgressBarContent = styled.div<{ marginLeft: number }>`
  display: flex;
  justify-content: flex-start;
  margin-left: ${(props): string => props.marginLeft.toString()}px;
  transition: margin-left ${(props): string => props['style-consts'].transitionDurationS}s ease-in-out;
  ${MEDIA.progressbar`
    margin-left: 0;
    justify-content: center;
  `}
  ${MEDIA.medium`
    width: 100%;
    max-width: 800px;
    margin: auto;
    justify-content: space-between;
  `}
`

export const ArrowRight = styled.span`
  width: 20px;
  height: 20px;
  display: block;
  background: transparent url(${ArrowRightImg}) no-repeat;
  background-size: contain;
  position: absolute;
  right: 5px;
  top: calc(50% - 10px);
  z-index: 2;
  ${MEDIA.progressbar`
    display: none;
  `}
`

export const ArrowLeft = styled.span`
  width: 20px;
  height: 20px;
  display: block;
  background: transparent url(${ArrowLeftImg}) no-repeat;
  background-size: contain;
  position: absolute;
  left: 5px;
  top: calc(50% - 10px);
  z-index: 2;
  ${MEDIA.progressbar`
    display: none;
  `}
`
