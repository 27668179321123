import styled from 'styled-components'

import COLORS from 'constants/colors'

import LAYOUT from 'constants/layout'

import MEDIA from 'constants/media'


import BgrOneSm from 'assets/images/backgrounds/quoting/bgr-one-sm.webp'
import BgrOneMd from 'assets/images/backgrounds/quoting/bgr-one-md.webp'
import BgrOneXxl from 'assets/images/backgrounds/quoting/bgr-one-xxl.webp'
import BgrTwoSm from 'assets/images/backgrounds/quoting/bgr-two-sm.webp'
import BgrTwoMd from 'assets/images/backgrounds/quoting/bgr-two-md.webp'
import BgrTwoXxl from 'assets/images/backgrounds/quoting/bgr-two-xxl.webp'
import BgrThreeSm from 'assets/images/backgrounds/quoting/bgr-three-sm.webp'
import BgrThreeMd from 'assets/images/backgrounds/quoting/bgr-three-md.webp'
import BgrThreeXxl from 'assets/images/backgrounds/quoting/bgr-three-xxl.webp'

import { Gray as ContentGray } from 'components/base/Content/styles'

import { Props } from './types'

export const QuotingWrap = styled.div<Props>`
  padding: 150px 20px 40px;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  ${MEDIA.medium`
    padding-top: 240px;
  `}
  ${(props: Props): string | false => props.bgImageIndex === 1 && `
    background-image: url(${BgrOneSm});
    ${MEDIA.medium`
      background-image: url(${BgrOneMd});
    `}
    ${MEDIA.xxlarge`
      background-image: url(${BgrOneXxl});
    `}
  `}
  ${(props: Props): string | false => props.bgImageIndex === 2 && `
    background-image: url(${BgrTwoSm});
    ${MEDIA.medium`
      background-image: url(${BgrTwoMd});
    `}
    ${MEDIA.xxlarge`
      background-image: url(${BgrTwoXxl});
    `}
  `}
  ${(props: Props): string | false => props.bgImageIndex === 3 && `
    background-image: url(${BgrThreeSm});
    ${MEDIA.medium`
      background-image: url(${BgrThreeMd});
    `}
    ${MEDIA.xxlarge`
      background-image: url(${BgrThreeXxl});
    `}
  `}
`

export const QuotingContent = styled.div<Props>`
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid ${COLORS.BORDERS};
  max-width: ${LAYOUT.WIDTHMEDIUM};
  margin: auto;
  padding: ${(props: Props): string => (props.noPadding ? '20px 0 0 0' : '0 20px')};
  width: 100%;
  ${ContentGray} {
    margin: 30px 0;
  }
  ${MEDIA.medium`
    width: 100%;
    padding: ${(props: Props): string => props.noPadding ? '80px 0 0 0' : '80px 80px 0 80px'};
    ${ContentGray} {
      margin: 60px 0;
    }
  `}
`

export const FormWrapper = styled.div`
  text-align: center;
  margin-top: -80px;
  ${MEDIA.medium`
    margin-top: -160px;
  `}
`
