import React from 'react'

import ProgressBarStep from './Step'

import {
  ProgressBarWrap,
  ProgressBarContentWrap,
  ProgressBarContent,
  ArrowLeft,
  ArrowRight,
} from './styles'

import { Props } from './types'

const ProgressBar: React.FC<Props> = ({
  children,
  isArrowVisible,
  marginLeft,
  scrollLeft,
  scrollRight,
  setFirstStepRef,
  setLastStepRef,
  setWrapperRef,
  steps = [],
  styleConsts,
  type,
}): React.ReactElement => (
  <ProgressBarWrap>
    {(isArrowVisible.left && <ArrowLeft onClick={scrollLeft} />) || undefined}
    <ProgressBarContentWrap ref={(ref): void => setWrapperRef(ref)}>
      <ProgressBarContent marginLeft={marginLeft} style-consts={styleConsts}>
        {children ||
            steps.map(({
              icon,
              status,
              title,
            }, i) => (
              <ProgressBarStep
                type={type}
                forwardRef={(ref: React.ReactNode): void => {
                  if (i === 0) {
                    return setFirstStepRef(ref)
                  }
                  else if (i === steps.length - 1) {
                    return setLastStepRef(ref)
                  }

                  return undefined
                }}
                key={icon}
                icon={icon}
                status={status}
                styleConsts={styleConsts}
                title={title}
              />
            ))}
      </ProgressBarContent>
    </ProgressBarContentWrap>
    {(isArrowVisible.right && <ArrowRight onClick={scrollRight} />) || undefined}
  </ProgressBarWrap>
)

export default ProgressBar
