import React from 'react'

import { Theme } from 'components/base/Button'
import Title from 'components/base/Title'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'

import { Props } from 'components/blocks/Modal/types'

import Modal from 'components/blocks/Modal/index'

const ModalMissingDriver: React.FC<Props> = (props): React.ReactElement => (
  <div>
    <Modal
      {...props}
      needHelp={true}
      label='Missing Driver Modal'
      buttons={[
        {
          label: 'Complete Information for Matt Smith',
          center: true,
          yellow: true,
          theme: Theme.link,
          to: '/quote/new/auto/missing-driver',
        },
        {
          label: 'Remove Matt Smith: No Longer in Household',
          center: true,
          yellow: true,
          theme: Theme.link,
          to: '/quote/new/auto/rate-calls/1.5',
        },
        {
          label: 'Remove Matt Smith: Unknown Driver',
          center: true,
          yellow: true,
          theme: Theme.link,
          to: '/quote/new/auto/rate-calls/1.5',
        },
      ]}
    >
      <Title level={3}>Oops!</Title>
      <Paragraph theme={ParagraphTheme.intro}>
        We found a driver, <strong>Matt Smith</strong>, missing from your
        entries. Please choose one of the options below.
      </Paragraph>
    </Modal>
  </div>
)

export default ModalMissingDriver
