import React from 'react'

import InsuranceTypeIcon from 'components/base/Icon/InsuranceTypeIcon'

import { Props } from './types'

const QuotingHeading: React.FC<Props> = ({
  theme,
  ...props
}): React.ReactElement => (
  <div>
    <InsuranceTypeIcon {...props} theme={theme} colored={true} />
  </div>
)

export default QuotingHeading
