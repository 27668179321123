export enum Theme {
  auto = 'auto',
  concierge = 'concierge',
  home = 'home',
  life = 'life',
  medicare = 'medicare',
  health = 'health',
}

export interface Props {
  bgImageIndex?: number,
  className?: string,
  noPadding?: boolean,
  theme?: Theme,
  title?: string | React.ReactElement,
}
